import { get, post, deletes } from 'common/request'

export default {
  // 获取文件目录
  fileClassList: args => get('/lbrary/lbraryTreeList', args),

  // 新增文件目录
  fileClassAdd: args => post('/lbrary/add', args),

  // 新增子文件目录
  fileClassAddSon: args => post('/lbrary/addSon', args),

  // 修改文件目录
  fileClassUpdate: args => post('/lbrary/update', args),

  // 删除文件目录
  fileClassDelete: args => deletes(`/lbrary/del?id=${args}`),

  // 模块类型
  dictData: args => get('/dict-data/getByDicKind', args),

  // 详情
  get: args => get(`/lbrary/get?id=${args}`),

  ajaxGetDictItems: (params) => get('/dict/queryItemByDictType', params),

  searchByKeywords: params => get('/lbrary/searchBy', params),

  // 目录树
  getTree: args => get('/lbrary/tree', args)
}
